import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/syeda/corvette/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Should You Pressure Wash Your Corvette Engine`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b11f5eb5338c8e168a3ec7fb79cc983c/b17f8/old_corvette_engine.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHnZFkwsP/EABsQAAICAwEAAAAAAAAAAAAAAAIRAQMAIzEy/9oACAEBAAEFAq9hXNOcn1Jki7//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAgMBAAAAAAAAAAAAAAABEQAQAhIhMf/aAAgBAQAGPwJ6PGEpPlGe1//EABwQAAICAgMAAAAAAAAAAAAAAAERAGEQIUFR0f/aAAgBAQABPyFCoFXPsIAtJj3L3DK6hCFtYf/aAAwDAQACAAMAAAAQ9+//xAAXEQADAQAAAAAAAAAAAAAAAAABEBFB/9oACAEDAQE/EBNX/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAAMAAgMAAAAAAAAAAAAAAQARITFBUaHR/9oACAEBAAE/EGmwVF3sru/UdWxQPpfFeIhiad1crFQLZkRqBiwe42zP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "corvette engine pressure wash",
            "title": "corvette engine pressure wash",
            "src": "/static/b11f5eb5338c8e168a3ec7fb79cc983c/e5166/old_corvette_engine.jpg",
            "srcSet": ["/static/b11f5eb5338c8e168a3ec7fb79cc983c/f93b5/old_corvette_engine.jpg 300w", "/static/b11f5eb5338c8e168a3ec7fb79cc983c/b4294/old_corvette_engine.jpg 600w", "/static/b11f5eb5338c8e168a3ec7fb79cc983c/e5166/old_corvette_engine.jpg 1200w", "/static/b11f5eb5338c8e168a3ec7fb79cc983c/b17f8/old_corvette_engine.jpg 1600w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Yes, if you want to save some time, then Pressure Wash could be the way. You can blast away all the dirt that accumulated under the hood through a pressure wash.`}</p>
    <p>{`First, you must be sure to park your Corvette in a place that is at least 10 degrees warmer than the engine. Also, you must not start the engine immediately after washing the engine. This is so that the temperature in the engine compartment is at a safe ambient level. Moreover, there must be no heat buildup. This will protect your Corvette's electrical components from getting damaged by water. Although the electrical components are weatherproof, they may condense internal moisture when cold water hits warm engine surfaces.`}</p>
    <p><strong parentName="p">{`How to pressure wash a Corvette engine`}</strong></p>
    <p>{`If you want to pressure wash your Corvette engine, follow these simple steps. First, make sure the engine is completely cool. Then, turn on the power washer to the lowest setting, and choose a wide-angle spray tip with a 25 to 40-degree angle to prevent water blasting into electrical components. Your pressure washer should have a PSI under 1,500, and you should hold the wand at least five feet away from the engine. Next, spray off the cleaner and residue.`}</p>
    <p>{`Next, make sure to dry the engine bay with a soft detailing cloth. The ceramic coating on the engine will take a few minutes to dry. If you do not have a leaf blower, use a soft detailing cloth. Do not forget the Original C8 Corvette Trunk Cover. Make sure the trunk cover is in place before pressure washing the engine. After washing, put the trunk cover back on the car.
The next step is cleaning the engine itself. This is easier than it may seem. All you need to do is remove the plastic coverings on the engine and then fill the spray gun with water. You can also turn on the engine and check for codes or strange noises. Afterward, you can apply water-based dressing to the engine to give it a shine. This step is not difficult and will ensure that the engine is clean and shiny.`}</p>
    <p><strong parentName="p">{`Things to remember while pressure wash your Corvette engine`}</strong></p>
    <p>{`You should not spray water near the optispark, and you should not clean a sealed LS motor with water. Even if it looks nice, a dirty engine can retain heat. Additionally, it makes it difficult to detect even small leaks, and it just does not look good. Newer Corvette engines use one ignition coil per cylinder. If you are cleaning a 1990-1996 LT series engine, you will run into ignition distributor issues.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      